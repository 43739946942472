import { useEffect, useMemo, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import hash from 'json-stable-stringify';
import { getCurrentPageId } from 'services/app/selectors';
import { IFRAME_ID, IMAGE_V2_ID, PANEL_V2_CATALOG } from 'components/objects/PanelV2/constants';
import panelPreviewMockData from 'components/objects/PanelV2/PanelPreviewMockData';
import { ICatalogItemProps as ICatalogItem } from 'components/objects/PanelV2/CatalogItem';
import { createPanel, setPendingPanelChange, setPreviewPanelRenderer, setRegionRendererDraft, deletePanel as deletePanelAction, createPanelSuccess, setMockPanelRenderer } from 'services/admin/actions';
import { ID_SIDEBAR } from 'services/renderer';
import { getCreatingKindPanel, getCreatingPanelAction, getPendingPagePanelsChanges, isCreatingPanel as isCreatingPanelSelector } from 'services/admin/selectors';
import { isEmpty, sortBy } from 'lodash';
import { dedupedBy } from 'array-utils';
import { usePanelsContext } from 'contexts/PanelsContext';
import { IPanelWithSidebarInfo, ISidebarPanelWithExtraInformation, ISidebarPanel } from './use-panels';

const MULTI_INSTANCE_PANELS_MAP = {
  [IMAGE_V2_ID]: true,
  [IFRAME_ID]: true,
};

type ICatalogItemWithSidebarInfo = ICatalogItem & ISidebarPanelWithExtraInformation;

const addPositionProperty = (panelListInfoWithoutPosition: ICatalogItemWithSidebarInfo[]): ICatalogItem[] => {
  return panelListInfoWithoutPosition.map((panel, index) => ({ ...panel, position: index + 1 } as ICatalogItem));
};

interface IUsePanels {
  catalogActivePanels: ICatalogItem[],
  catalogAvailablePanels: ICatalogItem[],
  closeEditPanel: () => void,
  deletePanel: (kindId: string) => void,
  disablePanel: (kind: string) => void,
  duplicatePanel: (kindId: string) => void,
  editPanel: (editingPanel: any) => void,
  enablePanel: (kind: string) => void,
  openEditPanel: (kindId: string) => void,
  previewPanel: (kind: string) => void,
  reorderPanel: (reorderedPanels: ICatalogItemWithSidebarInfo[]) => void,
}

const useSidebarPanelPicker = (): IUsePanels => {
  const dispatch = useDispatch();
  const {
    sidebarPanels,
    configuredGroupPanels,
    configuredPanelsMap,
    activePanelsMap,
    availablePanelsMap,
  } = usePanelsContext();

  const pageId = useSelector(getCurrentPageId);
  const editingPanels = useSelector(getPendingPagePanelsChanges);
  const isCreatingPanel = useSelector(isCreatingPanelSelector);
  const kindCreatingPanel = useSelector(getCreatingKindPanel);
  const creatingAction = useSelector(getCreatingPanelAction);

  const [panelsSize, setPanelsSize] = useState(0);
  const [currentEditingPanelKind, setCurrentEditingPanelKind] = useState<string | null>(null);
  const [tempReorderedPanels, setTempReorderedPanels] = useState<ICatalogItem[] | null>(null);

  const catalogActivePanels = useMemo(() => {
    const catalogMap = sortBy(Object.values(activePanelsMap), 'position')
      .map((activePanel, index) => {
        const baseCatalogItem = { ...PANEL_V2_CATALOG[activePanel.baseKind] } as ICatalogItemWithSidebarInfo;
        baseCatalogItem.isActive = activePanel.isActive;
        baseCatalogItem.isACopy = Boolean(activePanel.numCopy);
        baseCatalogItem.multiInstance = (activePanel.baseKind in MULTI_INSTANCE_PANELS_MAP);
        baseCatalogItem.position = index + 1;
        baseCatalogItem.kindId = activePanel.kind;
        baseCatalogItem.id = activePanel._id;
        baseCatalogItem.arrayId = activePanel.arrayId;
        return baseCatalogItem;
      });
      return catalogMap;
  }, [hash(activePanelsMap)]);

  const catalogAvailablePanels = useMemo(() => {
    return Object.keys(availablePanelsMap).map<ICatalogItemWithSidebarInfo>((availablePanelKind, index) => {
      const availablePanel: IPanelWithSidebarInfo = configuredPanelsMap[availablePanelKind];
      if (isEmpty(availablePanel)){
        return ({
          ...PANEL_V2_CATALOG[availablePanelKind],
          isActive: false,
          multiInstance: false,
          position: index + 1,
        } as ICatalogItemWithSidebarInfo);
      } else {
        return ({
          ...PANEL_V2_CATALOG[availablePanel.baseKind],
          kindId: availablePanel.kind,
          isActive: availablePanel.isActive,
          isACopy:  Boolean(availablePanel.numCopy),
          multiInstance: (availablePanel.baseKind in MULTI_INSTANCE_PANELS_MAP),
          id: availablePanel._id,
          arrayId: availablePanel.arrayId,
          position: index + 1,
        } as ICatalogItemWithSidebarInfo);
      }
    });
  }, [hash(availablePanelsMap)]);

  useEffect(() => {
    if (isCreatingPanel && kindCreatingPanel && Object.values(configuredPanelsMap)?.length > panelsSize) {
      if (creatingAction === 'enable') {
        enablePanel(kindCreatingPanel);
      } else if (creatingAction === 'edit') {
        openEditPanel(kindCreatingPanel);
      }
      dispatch(createPanelSuccess());
    }
  }, [hash(Object.values(configuredPanelsMap)?.length)]);

  useEffect(() => {
    // Used to remove tempReorderedPanels
    if (tempReorderedPanels) {
      setTempReorderedPanels(null);
    }
  }, [hash(catalogActivePanels)]);

  const withResetPreviewPanel = useCallback(
    <TFn extends (...args: any) => any>(fn: TFn): (...args: Parameters<TFn>) => void => {
      return (...args: any[]) => {
        dispatch(setMockPanelRenderer(null));
        fn(...args);
      };
    }, [dispatch]);


  const enablePanel = withResetPreviewPanel(useCallback((kind: string) => {
    if (kind in configuredPanelsMap) {
      const panelId = configuredPanelsMap[kind]._id;

      /**
       * It could be the case, that coming from panels V1, a channel could have more than one panels of the same kind
       * Panels V2, dedupe those panels and just present one of them. However, to handle enable/disable actions we need to make sure
       * all of those duplicated panels has the same state. If not we could keep enable a panel but it could keep disable because
       * there other instances are disabled.
       * TODO: We should consider delete these case on the DB
       */
      const legacyDuplicates = configuredGroupPanels[kind]?.filter(panel => !panel.numCopy);
      const hasLegacyDuplicates = legacyDuplicates?.length > 1;
      const toUpdatePanelIds = hasLegacyDuplicates
        ? legacyDuplicates.map(panel => panel._id)
        : [panelId];

      const newPanelList = sidebarPanels.filter(({ id }) => !toUpdatePanelIds.includes(id));
      const updatedList = sidebarPanels.filter(({ id }) => toUpdatePanelIds.includes(id));

      dispatch(setRegionRendererDraft(ID_SIDEBAR, {
        items: [
          ...newPanelList,
          ...updatedList.map(sidebarPanel => ({ ...sidebarPanel, isActive: true })),
        ],
      }));

    } else {
      setPanelsSize(Object.values(configuredPanelsMap)?.length);
      dispatch(createPanel({ kind, action: 'enable' }));
    }
  },[hash(configuredPanelsMap), hash(configuredGroupPanels), hash(sidebarPanels), dispatch ]));

  const disablePanel = withResetPreviewPanel(useCallback((kind: string) => {
    if (kind in configuredPanelsMap) {
      const panelId = configuredPanelsMap[kind]._id;

      const legacyDuplicates = configuredGroupPanels[kind]?.filter(panel => !panel.numCopy);
      const hasLegacyDuplicates = legacyDuplicates?.length > 1;
      const toUpdatePanelIds = hasLegacyDuplicates
        ? legacyDuplicates.map(panel => panel._id)
        : [panelId];

      const newPanelList = sidebarPanels.filter(({ id }) => !toUpdatePanelIds.includes(id));
      const updatedList = sidebarPanels.filter(({ id }) => toUpdatePanelIds.includes(id));

      dispatch(setRegionRendererDraft(ID_SIDEBAR, {
        items: [
          ...newPanelList,
          ...updatedList.map(sidebarPanel => ({ ...sidebarPanel, isActive: false })),
        ],
      }));
    }
  }, [hash(configuredPanelsMap), hash(configuredGroupPanels),hash(sidebarPanels), dispatch]));

  const reorderPanel = withResetPreviewPanel(useCallback((reorderedPanels: ICatalogItemWithSidebarInfo[]) => {
    /*
      We set temp reordered panels to avoid a flicker at the moment to reorder
      Because it takes a little bit of time until we update active panel list.
    */
    setTempReorderedPanels(addPositionProperty(reorderedPanels));
    const newActivePanelList = reorderedPanels.map<ISidebarPanel>(panel => ({
      id: panel.id,
      arrayId: panel.arrayId,
      isActive: panel.isActive,
    }));
    const newPanelList = dedupedBy([...newActivePanelList, ...sidebarPanels], 'id');
    dispatch(setRegionRendererDraft(ID_SIDEBAR, { items: newPanelList }));
  }, [hash(sidebarPanels), dispatch]));

  const openEditPanel = withResetPreviewPanel(useCallback((kindId: string) => {
    if (kindId in configuredPanelsMap) {
      const configuredPanel = configuredPanelsMap[kindId];
      const editingPanelRenderer =
        editingPanels?.[kindId] ||
        configuredPanel?.renderer ||
        configuredPanel?.data;

      setCurrentEditingPanelKind(kindId);
      dispatch(setPreviewPanelRenderer(editingPanelRenderer));
    } else {
      setPanelsSize(Object.values(configuredPanelsMap)?.length);
      dispatch(createPanel({ kind: kindId, action: 'edit' }));
    }
  }, [hash(configuredPanelsMap), hash(editingPanels), dispatch]));

  const closeEditPanel = withResetPreviewPanel(useCallback(() => {
    dispatch(setPreviewPanelRenderer(null));
    setCurrentEditingPanelKind(null);
  }, [dispatch]));

  const editPanel = withResetPreviewPanel(useCallback((editingPanelRendererInfo) => {
    const panelKind = currentEditingPanelKind || editingPanelRendererInfo.panelType;
    if (!panelKind) {
      return;
    }

    dispatch(setPreviewPanelRenderer(editingPanelRendererInfo));
    dispatch(
      setPendingPanelChange(pageId, panelKind, editingPanelRendererInfo),
    );
  }, [currentEditingPanelKind, pageId, dispatch]));

  const duplicatePanel = withResetPreviewPanel(useCallback((kindId: string) => {
    const toDuplicatePanel = configuredPanelsMap[kindId].renderer || configuredPanelsMap[kindId].data;
    const numCopy = configuredGroupPanels[kindId].reduce((max, panel) => {
      return Math.max(max, panel.numCopy || 0) + 1;
    }, 1);

    dispatch(createPanel({
      kind: kindId,
      action: 'duplicate',
      copyInformation: {
        panel: toDuplicatePanel,
        numCopy,
      },
    }));
  }, [hash(configuredPanelsMap), hash(configuredGroupPanels), dispatch]));

  // We can only delete duplicated panels
  const deletePanel = withResetPreviewPanel(useCallback((kind: string) => {
    const configuredPanel = configuredPanelsMap[kind];
    const panelRenderer = configuredPanel.renderer || configuredPanel.data;
    const baseKindId = kind.split('-')[0];

    dispatch(deletePanelAction({
      id: configuredPanel._id,
      kind: baseKindId,
      renderer: panelRenderer,
    }));
  }, [hash(configuredPanelsMap), dispatch]));

  const previewPanel = useCallback((kind: string) => {
    const baseKind = kind.split('-')[0];
    const mockData = panelPreviewMockData[baseKind];
    if (mockData) {
      dispatch(setMockPanelRenderer(panelPreviewMockData[baseKind]));
    }
  }, [dispatch]);

  return {
    catalogActivePanels: tempReorderedPanels || catalogActivePanels,
    catalogAvailablePanels,
    enablePanel,
    disablePanel,
    reorderPanel,
    openEditPanel,
    closeEditPanel,
    editPanel,
    duplicatePanel,
    deletePanel,
    previewPanel,
  };
};


export default useSidebarPanelPicker;
