import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ContractStatus } from 'services/billing/models';
import { setActiveTab } from 'services/admin/actions';
import { settingsTypes } from 'services/admin/constants';
import { showModal } from 'services/modals/actions';
import { ModalKinds } from 'services/modals/types';
import {
  getContractStatus,
  getIsFreeTrialExpired,
  getIsPastDue,
  getPurchasePlanSuccess,
} from 'services/billing/selectors';
import {
  sendHighPriorityNotification,
  sendLowPriorityNotification,
  sendMediumPriorityNotification,
} from 'services/admin-notifications/actions';
import { getTabName } from 'utils';
import { ADMIN_TEXT_100 } from 'style/constants';

export default function useFreeTrialNotifications(isFreeTrial: boolean) {
  const dispatch = useDispatch();
  const contractStatus = useSelector(getContractStatus);
  const isFreeTrialExpired = useSelector(getIsFreeTrialExpired);
  const isPastDue = useSelector(getIsPastDue);
  const isPurchasePlanSuccess = useSelector(getPurchasePlanSuccess);

  const expiredMessageKey = useMemo(() => {
    let msg = 'ADMIN_FREE_TRIAL_NAVBAR_WARNING';
    switch (contractStatus) {
      case ContractStatus.freeTrialExpired:
        msg = 'ADMIN_FREE_TRIAL_EXPIRED_NAVBAR_WARNING';
        break;
      case ContractStatus.pastDue:
        msg = 'ADMIN_PAST_DUE_NAVBAR_WARNING';
        break;
    }
    return msg;
  }, [contractStatus]);

  const onStartPurchase = (close: () => void, index: number) => {
    if (!index) {
      dispatch(setActiveTab(getTabName(settingsTypes.BILLING)));
      dispatch(showModal({ data: { locked: false }, kind: ModalKinds.purchasePlan }));
    }
    if (!isFreeTrialExpired && !isPastDue) {
      close();
    }
  };

  useEffect(() => {
    if (!isFreeTrial) {
      return;
    }

    if (isPurchasePlanSuccess) {
      dispatch(sendLowPriorityNotification({
        buttons: [{ textKey: 'OK' }],
        notificationTextKey: 'ADMIN_SETTINGS_BILLING_PLAN_FREE_TRIAL_SUCCESS',
      }));
      return;
    }

    if (isFreeTrialExpired || isPastDue) {
      dispatch(sendHighPriorityNotification({
        action: onStartPurchase,
        buttons: [{ textKey: 'ADMIN_SETTINGS_BILLING_PLAN_FREE_TRIAL_BUY_PLAN' }],
        notificationTextKey: expiredMessageKey,
      }));
      return;
    }

    dispatch(sendMediumPriorityNotification({
      action: onStartPurchase,
      buttons: [
        { textKey: 'ADMIN_SETTINGS_BILLING_PLAN_FREE_TRIAL_BUY_PLAN' },
        {
          background: 'transparent',
          css: 'padding: 0 11.5px;',
          icon: 'cancelShort',
          text: '',
          textColor: ADMIN_TEXT_100,
        },
      ],
      notificationTextKey: 'ADMIN_FREE_TRIAL_NAVBAR_WARNING',
    }));
  }, [
    expiredMessageKey,
    isFreeTrial,
    isPastDue,
    isPurchasePlanSuccess,
    isFreeTrialExpired,
  ]);
}
