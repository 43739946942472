import React from 'react';
import { isUrl } from 'url-utils';

export const HTTPS_PREFIX = 'https://';

const useLinkInputValidation = (initialValue: string) => {
  const [linkUrl, setLinkUrl] = React.useState(initialValue);
  const [linkUrlStatus, setLinkUrlStatus] = React.useState<'typing' | 'valid' | 'invalid'>('typing');

  const typingTimeoutRef = React.useRef<NodeJS.Timeout | null>(null);

  const onChange = (value: string) => {
    setLinkUrl(value);
    setLinkUrlStatus('typing');

    // clear the existing timeout if the user is still typing
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    // set a new timeout
    typingTimeoutRef.current = setTimeout(() => {
      // validate the URL after the user has stopped typing for half a second
      if (value === '') {
        setLinkUrlStatus('typing');
        return;
      }
      if (!isUrl(`${HTTPS_PREFIX}${value}`)) {
        setLinkUrlStatus('invalid');
      } else {
        setLinkUrlStatus('valid');
      }
    }, 500);
  };

  return {
    linkUrl,
    setLinkUrl,
    linkUrlStatus,
    setLinkUrlStatus,
    onChange,
  };
};

export default useLinkInputValidation;
