export const VIDEO_ID = 'video-player';

export const VIDEO_OVERLAY_ID = 'video-overlay';

export const VIDEO_WRAPPER_ID = 'video-object-wrapper';

export const GRID_REGION = 'BEEFY_GRID_REGION';

export const CONTENT_REGION = 'SITE_CONTENT_INNER_CONTAINER';

export const FOOTER_REGION = 'FOOTER_CONTENT';

export const BLACKOUT_LEFT_BOTTOM_ID = 'BLACKOUT_LEFT_BOTTOM_ID';

export const CONTENT_INNER_CONTAINER_ID = 'site-content';

export const TOOLBAR_PORTAL_LEFT = 'toolbar-portal-left';

export const TOOLBAR_PORTAL_CENTER = 'toolbar-portal-center';

export const TOOLBAR_PORTAL_RIGHT = 'toolbar-portal-right';

export const TOAST_PORTAL = 'toast-portal';

export const ADMIN_BAR_TAB_PORTAL = 'admin-bar-tab';

export const MODAL_PORTAL = 'modal-portal';

export const CONTENT_ROOT_ID = 'site';

export const ADMIN_ERROR_MODAL_ROOT = 'ADMIN_ERROR_MODAL';

export const SIDEBAR_ID = 'sidebar';

export const VIDEO_CONTROLS_ID = 'video-controls';

export const CHANNEL_SELECT_ID = 'channel-select';

export const HEADER_ID = 'header';

export const SOCIALS_ID = 'socials';

export const SPONSORS_ID = 'sponsors';

export const FOOTER_ID = 'footer';

export const GDPR_ID = 'gdpr';

export const NAVIGATION_ID = 'navigation';

export const COUNTDOWN_ID = 'countdown';

export const MOBILE_HEADER_ID = 'mobile-header';

export const MOBILE_TAB_ID = 'mobile-tab';

export const PANEL_SELECT_ID = 'panel-select';

export const QUEST_ID = 'quest';

export const AUTH_MODAL_ID = 'auth-modal';

export const OVERLAY_ID = 'overlay';

export const GATE_ACCESS_CODE_ID = 'gate-access-code';

export const GATE_PASSWORD_ID = 'gate-password';

export const GATE_ENTITLEMENT_ID = 'gate-entitlement';

export const GATE_LOGIN_ID = 'gate-login';

export const GATE_AMAZON_BENEFIT_ID = 'gate-amazon-benefit';

export const ENTITLEMENT_ID = 'entitlement';

export const PURCHASE_SUMMARY_TICKET = 'purchase-summary-ticket';

export const PURCHASE_SUMMARY_SUBSCRIPTION = 'purchase-summary-subscription';

export const PURCHASE_SUMMARY_BUNDLE = 'purchase-summary-bundle';

export const CREATE_ACCOUNT_ID = 'create-account';

export const ENTER_EMAIL_ID = 'enter-email';

export const ENTER_PASSWORD_ID = 'enter-password';

export const SITE_NAVIGATION_ID = 'site_navigation';

export const SITE_NAVIGATION_HEADER_ID = 'site_navigation-header';

export const SITE_STRUCTURE_CREATE_BUTTON_ID = 'site_structure_create';

export const SITE_STRUCTURE_BODY_ID = 'site_structure-body';

export const SITE_NAVIGATION_BODY_ID = 'site_navigation-body';

export const SITE_NAVIGATION_ITEM_ID = 'site_navigation-item';

export const SITE_NAVIGATION_ITEM_ID_MATCHED = 'site_navigation-matched';

export const SITE_NAVIGATION_THREE_DOTS_MENU_ID = 'site_navigation-three_dots_menu';

export const SITE_NAVIGATION_CARDS_MENU_ID = 'site_navigation-cards_menu';

export const SITE_NAVIGATION_CREATE_MODAL_ID = 'site_navigation-create_modal';

export const CART_ID = 'ecommerce-cart';

export const CART_PRODUCT_ID = 'ecommerce-cart-product';

export const PRODUCT_IMAGE_ID = 'ecommerce-product-image';

export const NAVIGATION_BLOCK_ID = 'navigation_block';

export const NAVIGATION_BLOCK_DROPDOWN_ID = 'navigation_block-dropdown';

export const NAVIGATION_BLOCK_EXTERNAL_LINKS_INPUTS_ID = 'navigation_block-external_links_inputs';

export const NAVIGATION_BLOCK_SITE_STRUCTURE_ID = 'navigation_block-site-structure';

export const NAVIGATION_BLOCK_SITE_NAVIGATION_ID = 'navigation_block-site_navigation';

export const NAVIGATION_BLOCK_SITE_NAVIGATION_ITEM_ID = 'navigation_block-site_navigation-item';

export const NAVIGATION_BLOCK_SITE_NAVIGATION_EXTERNAL_LINKS_ID = 'navigation_block-site_navigation-external_links';

export const NAVIGATION_BLOCK_SITE_NAVIGATION_EXTERNAL_LINK_ID = 'navigation_block-site_navigation-external_link';

export const NAVIGATION_BLOCK_SITE_NAVIGATION_EXTERNAL_LINKS_FORM_ID = 'navigation_block-site_navigation-external_links_form';

export const NAVIGATION_BLOCK_CUSTOM_NAVIGATION_ID = 'navigation_block-custom_navigation';

export const NAVIGATION_BLOCK_CUSTOM_NAVIGATION_DROPDOWN_ID = 'navigation_block-custom_navigation-dropdown';

export const NAVIGATION_BLOCK_CUSTOM_NAVIGATION_MENU_ITEM_ID = 'navigation_block-custom_navigation-menu_item';

export const NAVIGATION_BLOCK_CUSTOM_NAVIGATION_MENU_ITEM_THREE_DOTS_MENU_ID = 'navigation_block-custom_navigation-menu_item-three_dots_menu';

export const NAVIGATION_BLOCK_CUSTOM_NAVIGATION_MENU_ITEM_CARDS_MENU_ID = 'navigation_block-custom_navigation-menu_item-cards_menu';

export const NAVIGATION_BAR_ID = 'navigation_block-end-user-bar';

export const NAVIGATION_BAR_ITEM_ID = 'navigation_block-end-user-bar-item';

export const NAVIGATION_BAR_MORE_ID = 'navigation_block-end-user-bar-more';

export const NAVIGATION_BLOCK_CUSTOM_NAVIGATION_MENU_ITEM_LINK_THREE_DOTS_MENU_ID = 'navigation_block-custom_navigation-menu_item_link-three_dots_menu';

export const NAVIGATION_BLOCK_CUSTOM_NAVIGATION_MENU_ITEM_LINK_CARDS_MENU_ID = 'navigation_block-custom_navigation-menu_item_link-cards_menu';

export const NAVIGATION_BLOCK_CUSTOM_NAVIGATION_ITEM_ID = 'navigation_block-custom-navigation-item';

export const NAVIGATION_BLOCK_CUSTOM_NAVIGATION_ITEM_ACTIVE_ID = 'navigation_block-custom-navigation-item-active';

export const NAVIGATION_BLOCK_CREATE_CUSTOM_NAVIGATION_ID = 'navigation_block-create-custom-navigation';

export const NAVIGATION_BLOCK_CUSTOM_NAVIGATION_MENU_LINK_ITEM = 'navigation_block-custom_navigation-menu_link_item';

export const NAVIGATION_BLOCK_CUSTOM_NAVIGATION_REMOVE_ITEM_ID = 'custom_navigation-remove';

export const NAVIGATION_BLOCK_CUSTOM_NAVIGATION_RENAME_ITEM_ID = 'custom_navigation-rename';

export const NAVIGATION_BLOCK_CUSTOM_NAVIGATION_SELECT_OPTION_CREATE_FOLDER_ID = 'select_option_create_folder';

export const NAVIGATION_BLOCK_CUSTOM_NAVIGATION_SELECT_OPTION_CREATE_LINK_ID = 'select_option_create_link';

export const NAVIGATION_BLOCK_CUSTOM_NAVIGATION_SELECT_OPTION_ITEM_ID = 'select_option_item';

export const NAVIGATION_BLOCK_CUSTOM_NAVIGATION_CREATE_LINK_NAME_ID = 'create_link-link_name';

export const NAVIGATION_BLOCK_CUSTOM_NAVIGATION_CREATE_LINK_TRASHCAN_ID = 'create_link-trashcan';

export const NAVIGATION_BLOCK_CUSTOM_NAVIGATION_CREATE_LINK_CHECKMARK_ID = 'create_link-checkmark';

export const NAVIGATION_BLOCK_CUSTOM_NAVIGATION_CREATE_LINK_URL_ID = 'create_link-link_URL';

export const NAVIGATION_BLOCK_TOGGLE_ID = 'navigation_block-toggle';

export const DEVELOPER_TAB_ID = 'developer_tab';

export const DEVELOPER_TAB_CODE_INJECTION_SECTION_ID = 'developer_tab-code_injection_section';

export const DEVELOPER_TAB_API_KEY_SECTION_ID = 'developer_tab-api_key_section';

export const DEVELOPER_TAB_WEBHOOKS_SECTION_ID = 'developer_tab-webhooks_section';

export const PAGE_SETTINGS_VISIBILITY_ID = 'page_settings-visibility';

export const REMOVE_SPONSER_BUTTON_ID = 'remove-sponsor-button';
