import { useDispatch, useSelector } from 'react-redux';
import hash from 'json-stable-stringify';
import { useEffect, useMemo, useState } from 'react';
import { getPanelDrafts, isEditMode as isEditModeSelector } from 'services/admin/selectors';
import { getPanels } from 'services/app';
import useRealtimeDocuments from './use-realtime-documents';
import IPanel from 'models/IPanel';
import { isActivePanel } from 'components/objects/PanelV2/utils';
import groupBy from 'lodash/groupBy';
import { PANEL_V2_CATALOG } from 'components/objects/PanelV2/constants';
import IState from 'services/state';
import { isPanelEnabled as isPanelEnabledSelector } from 'services/feature-gate';
import { getActiveSitePanelsFeatures } from 'services/app/selectors/common';
import { setActivePanels } from 'services/user-layout/actions';
import { filterDict, reduceDict } from 'map-utils';
import { Dictionary, sortBy } from 'lodash';

export interface ISidebarPanel {
  arrayId: string,
  id: string,
  isActive?: boolean,
}

export interface ISidebarPanelWithExtraInformation extends ISidebarPanel {
  baseKind: string,
  kind: string,
}

export type IPanelWithSidebarInfo = IPanel<{}> & ISidebarPanelWithExtraInformation;

const getGroupsPanelsMap = (
  realtimePanels: IPanel<{}>[],
  panels: ISidebarPanel[],
  isGateFeaturedPanelEnabled: (kind: string) => boolean,
) => {
  const configuredPanels = realtimePanels
    .map((realtimePanel, index) => {
      const kind = realtimePanel?.renderer?.panelType || realtimePanel?.data?.kind;
      return ({
        ...realtimePanel,
        ...panels[index],
        position: index,
        isActive: isActivePanel(panels[index]),
        baseKind: kind,
        kind,
      } as IPanelWithSidebarInfo);
    }).filter(confPanel => confPanel.baseKind in PANEL_V2_CATALOG && isGateFeaturedPanelEnabled(confPanel.baseKind));
  return groupBy<IPanelWithSidebarInfo>(configuredPanels, 'baseKind');
};

const getConfiguredPanelsMap = (configuredGroupPanels) => {
  return Object.keys(configuredGroupPanels).reduce((panelsMap, kind) => {
    const panelGroup = configuredGroupPanels[kind];

    if (panelGroup.length === 1) {
      panelsMap[kind] = panelGroup[0];
    } else {
      for (const panel of panelGroup) {
        if (panel.numCopy) {
          // It handles kinds for duplicated panels (ex: image_v2, image_v2-2)
          const duplicateKindType = `${kind}-${panel.numCopy}`;
          panelsMap[duplicateKindType] = panel;
          panelsMap[duplicateKindType].kind = duplicateKindType;
        } else {
        // It handles kinds for panels and sites that has more than one panel of the same kind
          if (!(kind in panelsMap)) {
            panelsMap[kind] = panel;
          }
        }
      }
    }
    return panelsMap;
  }, {} as Dictionary<IPanelWithSidebarInfo>);
};

interface IUsePanels {
  activePanelsMap: Dictionary<IPanelWithSidebarInfo>,
  availablePanelsMap: Dictionary<IPanelWithSidebarInfo>,
  configuredGroupPanels: Dictionary<IPanelWithSidebarInfo[]>,
  configuredPanelsMap: Dictionary<IPanelWithSidebarInfo>,
  sidebarPanels: ISidebarPanel[],
}

const usePanels = (): IUsePanels => {
  const dispatch = useDispatch();

  const channelPanels: ISidebarPanel[] = useSelector(getPanels);
  const draftPanels: ISidebarPanel[] = useSelector(getPanelDrafts);
  const isEditMode = useSelector(isEditModeSelector);
  const gateFeaturedPanels = useSelector(getActiveSitePanelsFeatures);
  const isGateFeaturedPanelEnabled = useSelector(
    (state: IState) => (kind: string) => isPanelEnabledSelector(state, kind),
  );
  const [realtimePanels, setRealtimePanels] = useState<IPanel<{}>[]>([]);

  const panels = useMemo(() => {
    const updatedPanels = isEditMode ? draftPanels || channelPanels : channelPanels;
    return updatedPanels;
  }, [hash(draftPanels), hash(channelPanels), isEditMode]);

  const [rtPanels] =
    useRealtimeDocuments<IPanel<{}>>(panels.map(panel => ({ collection: 'objects', id: panel.id })));

  useEffect(() => {
    if (rtPanels.every(rtPanel => rtPanel)) {
      setRealtimePanels(rtPanels as IPanel<{}>[]);
    }
  }, [hash(rtPanels)]);

  const panelCatalog = useMemo(() => {
    return filterDict(PANEL_V2_CATALOG, (panel => isGateFeaturedPanelEnabled(panel.kindId)));
  }, [hash(gateFeaturedPanels), isGateFeaturedPanelEnabled]);

  const getAvailablePanels = (confGroupPanels: Dictionary<IPanelWithSidebarInfo[]>, activeMap: Dictionary<IPanelWithSidebarInfo>) => {
    const availableMap = reduceDict(panelCatalog, (panelsCatalogMap, catalogMap) => {
      const { kindId } = catalogMap;
      const groupPanels = confGroupPanels[kindId];

      // To handle configured panels
      if (groupPanels) {
        const sortedGroupPanels = sortBy(groupPanels, (panel) => {
          if (!panel.numCopy) {
            return 0;
          }
          return panel.numCopy;
        });
        for (const groupPanel of sortedGroupPanels) {
          const panelKindId = groupPanel.kind;
          if (!(groupPanel.kind in activeMap)) {
            panelsCatalogMap[panelKindId] = groupPanel;
          }
        }
      } else {
        // To handle no configured panels
        panelsCatalogMap[kindId] = {} as IPanelWithSidebarInfo;
      }

      return panelsCatalogMap;
    }, {} as Dictionary<IPanelWithSidebarInfo>);
    return availableMap;
  };

  const [configuredGroupPanels, configuredPanelsMap, activePanelsMap, availablePanelsMap] = useMemo(() => {
    const groupPanelsMap = getGroupsPanelsMap(realtimePanels, panels, isGateFeaturedPanelEnabled);
    const confPanelsMap = getConfiguredPanelsMap(groupPanelsMap);
    const activeMap = filterDict(confPanelsMap, (panel) => isActivePanel(panel));
    const availableMap = getAvailablePanels(groupPanelsMap, activeMap);

    return [groupPanelsMap, confPanelsMap, activeMap, availableMap];
  }, [hash(panels), hash(realtimePanels), isGateFeaturedPanelEnabled]);

  useEffect(() => {
    const activePanels = sortBy(Object.values(activePanelsMap), 'position');
    dispatch(setActivePanels(activePanels));
  }, [hash(activePanelsMap)]);

  return {
    sidebarPanels: panels,
    configuredGroupPanels,
    configuredPanelsMap,
    activePanelsMap,
    availablePanelsMap,
  };
};


export default usePanels;
