import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { Route, Switch } from 'react-router';
import App from 'components/application/App';
import { EMBED, OBJECT, SERVICE_BASE_URL } from 'config';
import devHelper from 'dev-helper';
import { store, history } from 'services';
import { ConnectedRouter } from 'connected-react-router';
import initReactFastclick from 'react-fastclick';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import SRESetup from './sre';
import { setupI18n } from 'services/localization';
import { persistenceService } from 'services/persistence';
import { setupUserInteractionListener } from 'services/interaction-observer';
import { configureRealtimeBridge } from 'services/realtime/bridge';
import Providers from 'Providers';
import './sentry';
import { PageType } from 'models/IObject';


axios.interceptors.request.use((request) => {
  if (request.url?.includes(SERVICE_BASE_URL)) {
    request.headers.originPath = window.location.pathname;
  }
  return request;
});

axiosRetry(axios, {
  retries: 2,
  retryCondition: (error) => {
    const status = error.response?.status || 0;
    return [400, 502].includes(status);
  },
});

initReactFastclick();

if (EMBED) {
  document.body.style.backgroundColor = 'transparent';
}

const container = document.getElementById('web-app');
if (!container) {
  throw new Error('No app container found');
}
Modal.setAppElement(container); // THIS IS IMPORTANT

setupUserInteractionListener();

Promise.all([
  persistenceService().refreshCache(),
  SRESetup(),
  setupI18n(),
  configureRealtimeBridge(store),
]).finally(() => {
  // Debug-only global dev helper (compiled out of prod bundle)

  const DEV_MODE = process.env.NODE_ENV !== 'production';
  if (DEV_MODE) {
    devHelper(store());
  }
  const Wrapper = DEV_MODE ? React.StrictMode : React.Fragment;

  const renderFn = OBJECT?.type === PageType.LANDING ? ReactDOM.hydrate : ReactDOM.render;

  renderFn(
    (
      <Wrapper>
        <Providers>
          <ConnectedRouter history={history}>
            <Switch>
              <Route component={App} path="*" />
            </Switch>
          </ConnectedRouter>
        </Providers>
      </Wrapper>
    ),
    container,
  );
});
