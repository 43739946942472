import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getPendingGateChange } from 'services/admin/selectors';
import { getObjectId, getSiteId } from 'services/app';
import { getPrimaryToken } from 'services/auth';
import { getChannelPassword } from 'services/gate/api';

const useGatePassword = () => {
  const primaryToken = useSelector(getPrimaryToken);
  const pageId = useSelector(getObjectId);
  const siteId = useSelector(getSiteId);
  const draftPagePassword = useSelector(getPendingGateChange)?.gate?.password || '';
  const [pagePassword, setPagePassword] = useState('');
  const [editingPassword, setEditingPassword] = useState('');

  const getGatePassword = useCallback(async () => (
    getChannelPassword(pageId, siteId, primaryToken)
  ), [siteId, pageId, primaryToken]);

  useEffect(() => {
    getGatePassword().then((password) => setPagePassword(password));
  }, []);

  useEffect(() => {
    setEditingPassword(draftPagePassword || pagePassword);
  }, [pagePassword, draftPagePassword]);

  return {
    pagePassword,
    editingPassword,
  };
};

export default useGatePassword;
